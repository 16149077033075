import React, { Component } from "react";

export default class BootstrapFormField extends Component {
  constructor(props) {
    super(props);
    this.state = { blurred: false }

    this.shouldShowError = this.shouldShowError.bind(this);
  }

  shouldShowError() {
    return this.props.error && (this.state.blurred || this.props.wasValidated);
  }

  render() {
    return (<div className={this.props.className}>
      <label htmlFor={this.props.id}>{this.props.label}</label>
      <input
        type={this.props.type}
        className={`${this.shouldShowError() ? "is-invalid" : ""} form-control`}
        id={this.props.id}
        value={this.props.value}
        onChange={this.props.onChange}
        onKeyUp={this.props.onKeyUp}
        name={this.props.name || this.props.id}
        placeholder={this.props.placeholder}
        onBlur={() => { this.setState({ blurred: true }) }}
      />
      {this.shouldShowError() &&
        (<div className="invalid-feedback">
          {this.props.error}
        </div>)
      }
    </div>)
  }
}
