import React from "react";
import styles from "./update-password.module.css";

const PasswordRequirement = (props) => {
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = [styles["must-line"]];
    if (meetsReq) classArr.push(styles["cross-out"]);
    return classArr.join(" ");
  };

  return (
    <div className="PasswordReqItem">
      <div className={`${styles["must-item"]}`}>
        <li className={`${styles["must-text"]}`}>{label}</li>
        <div className={setClass()}></div>
      </div>
    </div>
  );
};

export default PasswordRequirement;
