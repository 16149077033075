import http from "../http-common";

class InvoiceService {
  upload(files, userId, allowOverwrite) {
    const fd = new FormData()

    if (allowOverwrite) {
      fd.append("allowOverwrite", allowOverwrite);
    }

    for (let i = 0; i < files.length; ++i) {
      fd.append("invoices", files[i]);
    }

    return http.post(`/invoices/upload/${userId}`, fd);
  }

  list(userId) {
    return http.get(`/invoices/list/${userId}`);
  }

  download(filename) {
    return http.get(`/invoices/download/${filename}`, {responseType: "arraybuffer"});
  }

  delete(filename) {
    return http.delete(`/invoices/delete/${filename}`);
  }
}

export default new InvoiceService();
