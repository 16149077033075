import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styles from './sidenav-item.module.css';

export default class SidenavItem extends Component {
  render() {
    return (
      <NavLink exact={this.props.exact} to={this.props.to} className={styles.navitem} activeClassName={styles.active}>{this.props.children}</NavLink>
    );
  }
}