import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StateSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
  };

  static defaultProps = {
    label: "State"
  };

  constructor(props) {
    super(props);
    this.state = {
      blurred: false
    }

    this.shouldShowError = this.shouldShowError.bind(this);
  }

  shouldShowError() {
    return this.props.error && (this.state.blurred || this.props.wasValidated);
  }

  render() {
    return (
      <>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <select
          id={this.props.id}
          className={`${this.props.error && (this.state.blurred || this.props.wasValidated)
            ? "is-invalid" : ""} ${this.props.className}`}
          value={this.props.value}
          onChange={this.props.onChange}
          onBlur={() => this.setState({ blurred: true })}
          name={this.props.name}
        >
          <option value="">Choose state</option>
          <option>AL</option>
          <option>AK</option>
          <option>AZ</option>
          <option>CA</option>
          <option>CO</option>
          <option>CT</option>
          <option>DE</option>
          <option>FL</option>
          <option>GA</option>
          <option>HI</option>
          <option>ID</option>
          <option>IL</option>
          <option>IN</option>
          <option>IA</option>
          <option>KS</option>
          <option>KY</option>
          <option>LA</option>
          <option>ME</option>
          <option>MD</option>
          <option>MA</option>
          <option>MI</option>
          <option>MN</option>
          <option>MS</option>
          <option>MO</option>
          <option>MT</option>
          <option>NE</option>
          <option>NV</option>
          <option>NH</option>
          <option>NJ</option>
          <option>NM</option>
          <option>NY</option>
          <option>NC</option>
          <option>ND</option>
          <option>OH</option>
          <option>OK</option>
          <option>OR</option>
          <option>PA</option>
          <option>RI</option>
          <option>SC</option>
          <option>SD</option>
          <option>TN</option>
          <option>TX</option>
          <option>UT</option>
          <option>VT</option>
          <option>VA</option>
          <option>WA</option>
          <option>WV</option>
          <option>WI</option>
          <option>WY</option>
        </select>
        {this.shouldShowError() &&
          (<div className="invalid-feedback">
            {this.props.error}
          </div>
          )}
      </>
    );
  }
}