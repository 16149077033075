import React, { useEffect, useState } from "react";
import "datatables.net-select-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-editor-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net";
import $ from "jquery";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
import "datatables.net-select-bs4/css/select.bootstrap4.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
import "datatables.net-editor-bs4/css/editor.bootstrap4.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { STATES } from "./states";
import AuthService from "../../services/auth.service";
import DatatablesEditForm from "./datatables-edit-form.component";
import InvoiceListModal from "./invoice-list-modal.component";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";

/**
 * This component lists the users from the database in a table and allows
 * searching, editing, and deleting.
 *
 * It relies on datatables and datatables server.
 */
export default function UserTable() {
  const [notificationModalActive, setNotificationModalActive] = useState(false);
  const [notificationModalTitle, setNotificationModalTitle] = useState("");
  const [notificationModalBody, setNotificationModalBody] = useState("");

  const [invoiceModalActive, setInvoiceModalActive] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  function toggleInvoiceModal(e) {
    e.preventDefault();
    setSelectedUserId(+e.target.dataset.userid);
    setInvoiceModalActive((state) => !state);
  }

  const [resetPasswordModalActive, setResetPasswordModalActive] = useState(
    false
  );
  const [selectedEmail, setSelectedEmail] = useState();

  function toggleResetPasswordModal(e) {
    e.preventDefault();
    setSelectedEmail(e.target.dataset.email);
    setResetPasswordModalActive((state) => !state);
  }

  useEffect(() => {
    const ajax = {
      url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_DATATABLES_ENDPOINT}`,
      beforeSend: function (req) {
        const token = AuthService.getCurrentUser()?.accessToken;
        req.setRequestHeader("x-access-token", token);
      },
    };

    const editor = new $.fn.dataTable.Editor({
      ajax,
      table: "#users",
      template: "#user-form",
      formOptions: {
        main: {
          // Prevent submitting unchanged empty fields, because datatables will
          // send null fields as empty strings
          submit: "changed",
        },
      },
      fields: [
        {
          label: "First Name",
          name: "users.firstName",
          className: "block",
        },
        {
          label: "Last Name",
          name: "users.lastName",
          className: "block",
        },
        {
          label: "Organization",
          name: "users.organization",
          className: "block",
        },
        {
          label: "Account Type",
          name: "users.accountType",
          className: "block",
          type: "radio",
          options: [
            { label: "Organization", value: "Organization" },
            { label: "Personal", value: "Personal" },
          ],
        },
        {
          label: "Roles",
          name: "roles[].id",
          className: "block",
          type: "checkbox",
        },
        {
          label: "Phone Number",
          name: "users.phoneNumber",
          className: "block",
        },
        {
          label: "Email",
          name: "users.email",
          className: "block",
        },
        {
          label: "Address",
          name: "users.address",
          className: "block",
        },
        {
          label: "Address 2",
          name: "users.address2",
          className: "block",
        },
        {
          label: "City",
          name: "users.city",
          className: "block",
        },
        {
          label: "State",
          name: "users.state",
          className: "block",
          type: "select",
          options: STATES,
        },
        {
          label: "ZIP",
          name: "users.zip",
          className: "block",
        },
      ],
    });

    editor.on("open", function (e, mode, action) {
      const modal = document.querySelector(".modal-dialog");
      if (action === "edit") {
        modal.classList.add("modal-xl");
      } else {
        modal.classList.remove("modal-xl");
      }
    });

    const table = $("#users").DataTable({
      ajax,
      responsive: true,
      drawCallback() {
        document.querySelectorAll(".btn-col-cell:not(th)").forEach((cell) => {
          const { userid } = cell.firstElementChild.dataset;
          const { email } = cell.lastElementChild.dataset;
          ReactDOM.hydrate(
            <>
              <Button
                type="button"
                size="sm"
                className="mr-1"
                data-userid={userid}
                onClick={toggleInvoiceModal}
              >
                Invoices
              </Button>
              <Button
                type="button"
                size="sm"
                data-email={email}
                onClick={toggleResetPasswordModal}
              >
                Reset Password
              </Button>
            </>,
            cell
          );
        });
      },
      columns: [
        {
          data: "users.firstName",
          title: "First Name",
        },
        {
          data: "users.lastName",
          title: "Last Name",
        },
        {
          data: "users.organization",
          title: "Organization",
        },
        {
          data: "users.accountType",
          title: "Account Type",
        },
        {
          data: "users.email",
          title: "Email",
        },
        {
          data: null,
          orderable: false,
          className: "btn-col-cell",
          render: function (data, type, row, meta) {
            const userId = +data.DT_RowId.substring(4);
            return ReactDOMServer.renderToString(
              <>
                <Button
                  type="button"
                  size="sm"
                  className="mr-1"
                  data-userid={userId}
                  onClick={toggleInvoiceModal}
                >
                  Invoices
                </Button>
                <Button
                  type="button"
                  size="sm"
                  data-email={data.users.email}
                  onClick={toggleResetPasswordModal}
                >
                  Reset Password
                </Button>
              </>
            );
          },
        },
      ],
      select: true,
      lengthChange: false,
    });

    // Insert buttons instead of passing them in in the object above otherwise
    // they look misaligned.
    new $.fn.dataTable.Buttons(table, [
      { extend: "edit", editor: editor },
      { extend: "remove", editor: editor },
    ]);
    table
      .buttons()
      .container()
      .appendTo($($(".col-md-6").eq(0), table.table().container()));

    return () => $("#users").DataTable().destroy();
  }, []);

  return (
    <>
      {notificationModalActive && (
        <Modal
          isOpen={notificationModalActive}
          toggle={toggleResetPasswordModal}
        >
          {notificationModalTitle && (
            <ModalHeader>{notificationModalTitle}</ModalHeader>
          )}
          <ModalBody>{notificationModalBody}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => setNotificationModalActive(false)}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {invoiceModalActive && (
        <InvoiceListModal
          isOpen={invoiceModalActive}
          toggle={toggleInvoiceModal}
          userId={selectedUserId}
        />
      )}
      {resetPasswordModalActive && (
        <Modal
          isOpen={resetPasswordModalActive}
          toggle={toggleResetPasswordModal}
        >
          <ModalHeader>Reset Password?</ModalHeader>
          <ModalBody>
            Are you sure you want to reset this user's password?
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                AuthService.resetPassword(selectedEmail)
                  .then((resp) => {
                    if (resp.status !== 200) {
                      return Promise.reject(
                        "Received a status code other than 200"
                      );
                    }
                    setResetPasswordModalActive(false);
                    setNotificationModalTitle(
                      "An email has been sent to the user"
                    );
                    setNotificationModalBody(
                      "An email has been sent to the user. They may use the link provided in the email to reset their password."
                    );
                    setNotificationModalActive(true);
                  })
                  .catch(() => {
                    setResetPasswordModalActive(false);
                    setNotificationModalTitle("An error has occurred");
                    setNotificationModalBody(
                      "An error occurred while tryng to reset the user's password"
                    );
                    setNotificationModalActive(true);
                  });
              }}
            >
              Continue
            </Button>
            <Button color="secondary" onClick={toggleResetPasswordModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <DatatablesEditForm id="user-form" />
      <table id="users" className="table w-100" />
    </>
  );
}
