import React, { useState, useRef } from "react";
import styles from "./accordion.module.css";
import Chevron from "../chevron/chevron.component";

export default function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState(`${styles.icon}`);

  const content = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
    setRotateState(setActive === "active" ? `${styles.icon}` : `${styles.icon} ${styles.rotate}`);
  }

  return (
    <div className="d-flex flex-column">
      <button className={`${styles.accordion} ${setActive}`} onClick={toggleAccordion}>
        <p className={styles.title}>{props.question}</p>
        <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={styles.content}
      >
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: props.answer}}
        />
      </div>
    </div>
  );
}
