import React, { Component } from 'react';
import UserTable from './user-table.component';

export default class UserList extends Component {
  render() {
    return (
      <div className="d-flex flex-column flex-grow-1">
        <h2>Users</h2>

        {/* Set x-margins to 0, otherwise <hr> will be shrunk and won't be
        visible inside flexbox. */}
        <hr className="mx-0" />
        <UserTable />
      </div>
    );
  }
}
