import http from "../http-common";
import BasicService from "./basic_service";

class UserDataService extends BasicService {
  constructor() {
    super('/users');
  }

  count() {
    return http.get("/users/count");
  }

  deleteAll() {
    return http.delete("/users");
  }

  search(data) {
    return http.post("/users/search", data);
  }

  getComputer(userId) {
    return http.get(`/users/${encodeURIComponent(userId)}/computer`);
  }
}

export default new UserDataService();
