import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./datatables-edit-form.module.css";
import "./dte.css";

/**
 * This component is meant to be used with the UserTable component. It provides
 * the layout of the datatables editor form.
 */
export default class DatatablesEditForm extends Component {
  static propTypes = {
    id: PropTypes.string
  }

  static defaultProps = {
    id: "user-form"
  }
  render() {
    return (
      <div id={this.props.id} className={styles["edit-form"]}>
        <fieldset className={styles.personal}>
          <legend>Personal Info</legend>
          <div className={styles.row}>
            <editor-field name="users.firstName" />
            <editor-field name="users.lastName" />
          </div>

          <editor-field name="users.organization" />
          <div className={styles.row}>
            <editor-field name="users.email" />
            <editor-field name="users.phoneNumber" />
          </div>
        </fieldset>

        <fieldset className={styles.account}>
          <legend>Account</legend>
          <editor-field name="users.accountType" />
          <editor-field name="roles[].id" />
        </fieldset>

        <fieldset className={styles.address}>
          <legend>Address</legend>
          <editor-field name="users.address" />
          <editor-field name="users.address2" />
          <div className={styles.row}>
            <editor-field name="users.city" />
            <editor-field name="users.state" />
            <editor-field name="users.zip" />
          </div>

        </fieldset>
      </div>
    );
  }
}
