import http from "../http-common";

/**
 * This service serves as a base class for other services used for querying the
 * API. It supports a common set of requests that each part of the API supports.
 */
export default class BasicService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getAll() {
    return http.get(this.baseUrl);
  }

  getSubset(offset, limit) {
    return http.get(
      `${this.baseUrl}/?offset=${encodeURIComponent(offset)}&limit=${encodeURIComponent(limit)}`);
  }

  get(id) {
    return http.get(`${this.baseUrl}/${encodeURIComponent(id)}`);
  }

  create(data) {
    return http.post(`${this.baseUrl}`, data);
  }

  update(id, data) {
    return http.put(`${this.baseUrl}/${encodeURIComponent(id)}`, data);
  }

  delete(id) {
    return http.delete(`${this.baseUrl}/${encodeURIComponent(id)}`);
  }

}