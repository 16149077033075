import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styles from './dashboard-sidenav.module.css';
import SidenavItem from "../sidenav/sidenav-item.component";
import PropTypes from "prop-types";
import AuthService from "../../services/auth.service";

class DashboardSidenav extends Component {
  static propTypes = {
    baseUrl: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.signout = this.signout.bind(this);
  }

  signout() {
    AuthService.signout();
    this.props.history.push("/login");
  }

  render() {
    const baseUrl = this.props.baseUrl;
    return (
      <div
        className={
          `${this.props.className
            ? this.props.className
            : ""} ${styles.sidenav}
            d-flex flex-column bg-baird-point px-2 pb-2`}>

        <Link to="/">
          <img
            className="d-block mx-auto"
            src="/icons/128x128.png"
            width="128"
            height="128"
            alt="BCT"
          />
        </Link>
        <nav className={styles["sidenav-links"]}>
          <SidenavItem to={`${baseUrl}/users`}>Users</SidenavItem>
          <SidenavItem to={`${baseUrl}/registration`}>Registration</SidenavItem>
        </nav>
        <span className={styles.spacer} />
        <button
          className="btn btn-sm btn-secondary"
          type="button"
          onClick={this.signout}
        >Sign Out</button>
      </div>
    );
  }
}

export default withRouter(DashboardSidenav);
