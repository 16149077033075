import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer
        className={`${
          this.props.className ?? ""
        } bg-harriman-blue text-light d-flex justify-content-end px-2 py-1 mt-3`}
      >
        <small>
          &copy; {new Date().getFullYear()} University at Buffalo Division of
          Behavioral Medicine. All rights reserved.
        </small>
      </footer>
    );
  }
}
