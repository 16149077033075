import React, { Component } from 'react';
import RoleService from '../../services/role.service';

export default class RoleSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    RoleService.list()
      .then((resp) => {
        const roles = resp.data;
        const roleStates = {};
        roles.forEach((role) => roleStates[role.name] = false);

        if (Object.keys(roleStates).includes("user")) {
          roleStates["user"] = true;
          this.props.addRole("user");
        }

        this.setState({ roles, ...roleStates });
      })
  }

  onChange(e) {
    const state = {};
    state[e.target.id] = e.target.checked;
    this.setState(state);

    if (e.target.checked) {
      this.props.addRole(e.target.name);
    } else {
      this.props.removeRole(e.target.name);
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.state.roles.map((role) => {
          return (
            <div key={role.id} className={this.props.wrapperClassName}>
              <input
                type="checkbox"
                id={role.name}
                name={role.name}
                checked={this.state[role.name]}
                onChange={this.onChange}
                className={this.props.inputClassName}
              />
              <label htmlFor={role.name} className={this.props.labelClassName}>
                {role.name}
              </label>

            </div>);
        })}
        {this.props.error && this.props.wasValidated &&
          (<small className="text-danger">
            {this.props.error}
          </small>
          )}
      </div>
    );
  }
}
