import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./homepage.module.css";
import Navbar from "../navbar/navbar.component";
import Footer from "../footer/footer.component";
import { getPlatform } from "../../utilities/platform-detection";
import { Button, Collapse } from "reactstrap";

const supportedPlatforms = ["Windows", "Mac"];
const downloadPaths = new Map([
  ["Windows", `${process.env.PUBLIC_URL}/installers/BCT 2-latest.exe`],
  ["Mac", `${process.env.PUBLIC_URL}/installers/BCT 2-latest.dmg`],
]);

export default function Homepage() {
  const [showOtherDownloads, setShowOtherDownloads] = useState(false);
  function toggleShowOtherDownloads() {
    setShowOtherDownloads((state) => !state);
  }
  const platform = getPlatform();
  const isSupportedPlatform = supportedPlatforms.includes(platform);

  return (
    <div className="d-flex flex-column">
      <Navbar />
      <div className="container mt-3 my-auto">
        <div className="d-flex flex-wrap flex-lg-nowrap flex-xl-nowrap align-items-center justify-content-center">
          <img
            className="img-fluid"
            src="/icons/512x512.png"
            width="512"
            height="512"
            alt="BCT"
          ></img>

          <div className={styles.description}>
            <h1>Behavioral Choice Task</h1>
            <p>
              A flexible approach to studying reinforcing value as well as
              habituation. With BCT you can create tasks for delivery of a
              specific reinforcer with options for multiple reinforcers and a
              variety of schedule options.
            </p>
            {isSupportedPlatform ? (
              <Link
                to={downloadPaths.get(platform)}
                className="btn btn-ub-blue"
                download
                target="_blank"
              >
                Download for {platform}
              </Link>
            ) : (
              <div>Your detected platform is not supported.</div>
            )}
            <Button
              type="button"
              color="link"
              className="d-block text-ub-blue m-0 p-0 shadow-none"
              onClick={toggleShowOtherDownloads}
            >
              {`${showOtherDownloads ? "Hide" : "Show"}${
                isSupportedPlatform ? " other " : " "
              }downloads`}
            </Button>
            <Collapse isOpen={showOtherDownloads}>
              {supportedPlatforms
                .filter((val) => val !== platform)
                .map((val) => (
                  <Link
                    to={downloadPaths.get(val)}
                    key={val}
                    className="text-ub-blue d-block"
                    download
                    target="_blank"
                  >
                    {`Download for ${val}`}
                  </Link>
                ))}
            </Collapse>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
