import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json"
  }
});

// Check for user access token and send it with request if found
http.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    config.headers["x-access-token"] = user.accessToken;
  }

  return config;
}, (error) => Promise.reject(error));

export default http;
