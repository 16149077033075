import http from "../http-common";

class AuthService {
  signup(data) {
    return http.post("/auth/signup", data);
  }

  signin(credentials) {
    return http.post("/auth/signin", credentials)
      .then((resp) => {
        if (resp.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(resp.data));
        }

        return resp.data;
      });
  }

  signout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  updatePassword(userId, token, password) {
    return http.post(`/receive_new_password/${userId}/${token}`, { password })
      .then(resp => resp)
      .catch(err => console.warn("ERROR FROM SERVER UPDATING PASSWORD:", err));
  }

  resetPassword(email) {
    return http.post(`/reset_password/user/${email}`);
  }


  // Returns a boolean indicating whether the current user is an admin
  // NOTE: The access tokens is automatically sent with the request
  verifyAdmin() {
    return http.get("/test/admin")
      .then((resp) => {
        if (resp.status !== 200) {
          throw new Error(`Expected response code "200" but got "${resp.status}"`);
        }
        return true;
      })
      .catch((e) => {
        // Clear the access token on failure, if it exists. It may be invalid or
        // expired.
        this.signout();
        return false;
      });
  }
}

export default new AuthService();
