import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import validator from "validator";
import StateSelect from "./state-select.component";
import RoleSelector from "./role-selector.component";
import BootstrapFormField from "../bootstrap/bootstrap-form-field.component";

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeAccountType = this.onChangeAccountType.bind(this);
    this.onChangeOrganization = this.onChangeOrganization.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeAddress2 = this.onChangeAddress2.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeZip = this.onChangeZip.bind(this);
    this.newUser = this.newUser.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.addRole = this.addRole.bind(this);
    this.removeRole = this.removeRole.bind(this);
    this.defaultState = this.defaultState.bind(this);

    this.state = this.defaultState();
  }

  defaultState() {
    return {
      id: null,
      roles: [],
      accountType: "Personal",
      firstName: "",
      lastName: "",
      organization: "",
      email: "",
      phoneNumber: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      wasValidated: false,
      submitted: false,
      serverErrorMessage: "",
      password: "",
      errors: {
        firstName: "This field is required.",
        lastName: "This field is required.",
        organization: "This field is required.",
        email: "Invalid email address!",
        phoneNumber: "Phone Number must be 10 characters long!",
        address: "This field is required.",
        city: "This field is required.",
        state: "This field is required.",
        zip: "Invalid Zip Code.",
        roles: "At least one role must be selected."
      },
    };
  }

  requiredFieldErrorMessage(value) {
    if (value.length === 0) {
      return "This field is required."
    }
    return "";
  }

  onChangeFirstName(e) {
    const firstName = e.target.value;
    const errors = this.state.errors;
    errors.firstName = this.requiredFieldErrorMessage(firstName);

    this.setState({ firstName, errors });
  }

  onChangeLastName(e) {
    const lastName = e.target.value;
    const errors = this.state.errors;
    errors.lastName = this.requiredFieldErrorMessage(lastName);

    this.setState({ lastName, errors });
  }

  onChangeAccountType(e) {
    this.setState({ accountType: e.target.value });
  }

  onChangeOrganization(e) {
    const organization = e.target.value;
    const errors = this.state.errors;
    errors.organization = this.requiredFieldErrorMessage(organization);
    this.setState({
      organization
    });
  }

  onChangeEmail(e) {
    const email = e.target.value;
    let errors = this.state.errors;

    if (validator.isEmail(email) && !validator.isEmpty(email)) {
      errors.email = "";
    } else {
      errors.email = "Invalid email address!";
    }
    this.setState({
      email: email
    });
  }

  onChangePhoneNumber(e) {
    const phoneNumber = e.target.value;
    let errors = this.state.errors;

    if (validator.isMobilePhone(phoneNumber, "en-US")
      && !validator.isEmpty(phoneNumber)) {
      errors.phoneNumber = "";
    } else {
      errors.phoneNumber = "Phone Number must be 10 characters long!";
    }
    this.setState({
      phoneNumber: phoneNumber
    });
  }

  onChangeAddress(e) {
    const address = e.target.value;
    let errors = this.state.errors;
    errors.address = this.requiredFieldErrorMessage(address);

    this.setState({ address, errors });
  }

  onChangeAddress2(e) {
    this.setState({
      address2: e.target.value
    });
  }

  onChangeCity(e) {
    const city = e.target.value;
    let errors = this.state.errors;
    errors.city = this.requiredFieldErrorMessage(city);
    this.setState({ city, errors });
  }

  onChangeState(e) {
    const state = e.target.value;
    let errors = this.state.errors;
    errors.state = this.requiredFieldErrorMessage(state);
    this.setState({ state, errors });
  }

  onChangeZip(e) {
    e.preventDefault();
    const zip = e.target.value;
    let errors = this.state.errors;

    if (validator.isPostalCode(zip, "US") && !validator.isEmpty(zip)) {
      errors.zip = "";
    } else {
      errors.zip = "Invalid Zip Code.";
    }
    this.setState({ zip, errors });
  }

  addRole(role) {
    const roles = [...this.state.roles, role];
    const errors = this.state.errors;
    if (roles.length) {
      errors.roles = "";
    }
    this.setState({ roles, errors });
  }

  removeRole(role) {
    const roles = this.state.roles;
    const indexOfRole = roles.indexOf(role);
    if (indexOfRole !== -1) {
      this.setState({ roles: roles.filter((x, i) => i !== indexOfRole) });
    }

    if (indexOfRole !== -1 && roles.length <= 1) {
      const errors = this.state.errors;
      errors.roles = "At least one role must be selected.";
      this.setState({ errors });
    }

  }

  saveUser(e) {
    e.preventDefault();
    this.setState({ wasValidated: true });
    const errors = this.state.errors;
    const hasErrors = Object.values(errors).some(error => error !== "");
    if (hasErrors) {
      return;
    }

    var data = {
      roles: this.state.roles,
      accountType: this.state.accountType,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      organization: this.state.organization,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      password: this.state.password
    };

    AuthService.signup(data)
      .then((response) => {
        this.setState({
          ...this.defaultState(),
          submitted: true,
          password: response.data.password
        });
      })
      .catch((e) => {
        this.setState({ serverErrorMessage: e.response.data.message });
      });
  }

  newUser(e) {
    e.preventDefault();
    this.setState(this.defaultState());
  }

  render() {
    return (
      <form id="registration-form" className="submit-form" onSubmit={this.saveUser}>
        {this.state.submitted ? (
          <div>
            <h4>Your submission has been successfully submitted!</h4>
            <h5 calss="m-4">Here is the temporary password: <small class="text-muted"><mark>{this.state.password}</mark></small></h5>
            <p class="font-weight-lighter font-italic mb-4 mt-3 text-danger">*Please remember to ask user to change their password.</p>
            <button type="button" className="btn btn-success" onClick={this.newUser}>
              Add
            </button>
          </div>
        ) : (
            <>
              {this.state.serverErrorMessage &&
                <div className="alert alert-danger" role="alert">
                  {this.state.serverErrorMessage}
                </div>}
              <div className="form-group">
                <div className="form-row">
                  <BootstrapFormField
                    label="First Name"
                    type="text"
                    className="col-md"
                    id="firstName"
                    value={this.state.firstName}
                    onChange={this.onChangeFirstName}
                    name="firstName"
                    error={this.state.errors.firstName}
                    wasValidated={this.state.wasValidated}
                  />
                  <BootstrapFormField
                    label="Last Name"
                    type="text"
                    className="col-md"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={this.onChangeLastName}
                    name="lastName"
                    error={this.state.errors.lastName}
                    wasValidated={this.state.wasValidated}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md">
                  <div className="form-group">
                    <div>Roles</div>
                    <RoleSelector
                      wrapperClassName="form-check form-check-inline"
                      inputClassName="form-check-input"
                      labelClassName="form-check-label"
                      addRole={this.addRole}
                      removeRole={this.removeRole}
                      error={this.state.errors.roles}
                      wasValidated={this.state.wasValidated}
                    />
                  </div>
                </div>

                <div className="col-md">
                  <div className="form-group">
                    <div>Account Type</div>
                    <div className="form-check form-check-inline">
                      <input
                        id="account-type-organization"
                        className="form-check-input"
                        name="account-type"
                        type="radio"
                        value="Organization"
                        checked={this.state.accountType === "Organization"}
                        onChange={this.onChangeAccountType}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="account-type-organization">Organization</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="account-type-personal"
                        className="form-check-input"
                        name="account-type"
                        type="radio"
                        value="Personal"
                        checked={this.state.accountType === "Personal"}
                        onChange={this.onChangeAccountType}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="account-type-personal">Personal</label>
                    </div>
                  </div>
                </div>

              </div>

              <BootstrapFormField
                label="Organization Name"
                type="text"
                className="form-group"
                id="organization"
                value={this.state.organization}
                onChange={this.onChangeOrganization}
                name="organization"
                placeholder="Unversity at Buffalo"
                error={this.state.errors.organization}
                wasValidated={this.state.wasValidated}
              />

              <div className="form-row">
                <BootstrapFormField
                  className="form-group col-md-7"
                  type="text"
                  id="email"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  name="email"
                  label="Email Address"
                  placeholder="someone@example.com"
                  error={this.state.errors.email}
                  wasValidated={this.state.wasValidated}
                />
                <BootstrapFormField
                  className="col-md-5"
                  label="Phone Number"
                  type="tel"
                  id="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.onChangePhoneNumber}
                  name="phoneNumber"
                  error={this.state.errors.phoneNumber}
                  wasValidated={this.state.wasValidated}
                />
              </div>

              <BootstrapFormField
                className="form-group"
                label="Address"
                type="text"
                id="address"
                value={this.state.address}
                onChange={this.onChangeAddress}
                name="address"
                placeholder="123 Main St."
                error={this.state.errors.address}
                wasValidated={this.state.wasValidated}
              />

              <BootstrapFormField
                className="form-group"
                label="Address 2"
                type="text"
                id="address2"
                value={this.state.address2}
                onChange={this.onChangeAddress2}
                name="address2"
                placeholder="Apartment, Suite, or floor"
              />

              <div className="form-row">
                <BootstrapFormField
                  className="form-group col-md-6"
                  label="City"
                  type="text"
                  id="city"
                  value={this.state.city}
                  onChange={this.onChangeCity}
                  name="city"
                  error={this.state.errors.city}
                  wasValidated={this.state.wasValidated}
                />


                <div className="form-group col-md-4">
                  <StateSelect
                    id="state"
                    className="form-control"
                    value={this.state.state}
                    onChange={this.onChangeState}
                    name="state"
                    error={this.state.errors.state}
                    wasValidated={this.state.wasValidated}
                  />
                </div>

                <BootstrapFormField
                  label="ZIP"
                  className="form-group col-md-2"
                  id="zip"
                  value={this.state.zip}
                  onChange={this.onChangeZip}
                  name="zip"
                  error={this.state.errors.zip}
                  wasValidated={this.state.wasValidated}
                />
              </div>

              <button className="btn btn-primary">Submit</button>
            </>
          )}
      </form>
    );
  }
}
