import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./update-password.module.css";
import PasswordRequirement from "./password-requirement.component";
import BootstrapFormField from "../bootstrap/bootstrap-form-field.component";
import AuthService from "../../services/auth.service";

export default class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form inputs
      password: "",
      confirmPassword: "",
      submitted: false,

      // password validation booleans
      containsUL: false, // uppercase letter
      containsLL: false, // lowercase letter
      containsN: false, // number
      containsSC: false, // special character
      contains8C: false, // min 8 characters
      passwordMatch: false, // passwords match
    };
  }

  validatePassword = () => {
    // Validate if password has uppercase letter
    if (this.state.password.toLowerCase() !== this.state.password) {
      this.setState({ containsUL: true });
    } else {
      this.setState({ containsUL: false });
    }

    // Validate if password has lowercase letter
    if (this.state.password.toUpperCase() !== this.state.password)
      this.setState({ containsLL: true });
    else this.setState({ containsLL: false });

    // Validate if password has number
    if (/\d/.test(this.state.password)) this.setState({ containsN: true });
    else this.setState({ containsN: false });

    // Validate if password has special character
    if (/[~`!#@$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(this.state.password))
      this.setState({ containsSC: true });
    else this.setState({ containsSC: false });

    // Validate if password has at least 8 characters
    if (this.state.password.length >= 8) this.setState({ contains8C: true });
    else this.setState({ contains8C: false });

    // Validate if password and confirmPassword are matched
    if (
      this.state.password !== "" &&
      this.state.password === this.state.confirmPassword
    )
      this.setState({ passwordMatch: true });
    else this.setState({ passwordMatch: false });
  };

  updatePassword = (e) => {
    e.preventDefault();
    const { userId, token } = this.props;
    const { password } = this.state;

    AuthService.updatePassword(userId, token, password);
    this.setState({ submitted: !this.state.submitted });
  };

  render() {
    const { submitted } = this.state;
    const allValid =
      this.state.containsUL &&
      this.state.containsLL &&
      this.state.containsN &&
      this.state.containsSC &&
      this.state.contains8C &&
      this.state.passwordMatch;

    // labels and state boolean corresponding to each validation
    const mustContainData = [
      ["An uppercase letter (A-Z)", this.state.containsUL],
      ["A lowercase letter (a-z)", this.state.containsLL],
      ["A number (0-9)", this.state.containsN],
      ["A special character (!@#$)", this.state.containsSC],
      ["At least 8 characters", this.state.contains8C],
      ["Passwords match", this.state.passwordMatch],
    ];

    return (
      <div className="container-fluid px-0 h-100 bg-ub-blue">
        <div className="row no-gutters h-75 align-items-center">
          <div
            className={`${styles["update-password-card-wrapper"]} col-xl-3 mx-auto`}
          >
            <div className={`card`}>
              <div className="card-body">
                <img
                  className="d-block mx-auto"
                  src="/icons/128x128.png"
                  width="128"
                  height="128"
                  alt="BCT"
                />
                <h5 className="card-title text-center mb-4">
                  Resetting Password
                </h5>
                {submitted ? (
                  <div className="text-center">
                    <p>Your password has been updated.</p>
                    <p>
                      Please proceed to BCT desktop application to login using
                      your new password.
                    </p>
                  </div>
                ) : (
                  <div>
                    <form onSubmit={this.updatePassword}>
                      <BootstrapFormField
                        className="form-group"
                        label="New Password"
                        type="password"
                        id="password"
                        value={this.state.password}
                        placeholder="Enter new password"
                        onChange={(e) =>
                          this.setState(
                            { password: e.target.value },
                            this.validatePassword
                          )
                        }
                      />
                      <BootstrapFormField
                        className="form-group"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        value={this.state.confirmPassword}
                        placeholder="Enter new password again"
                        onChange={(e) =>
                          this.setState(
                            { confirmPassword: e.target.value },
                            this.validatePassword
                          )
                        }
                      />

                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                        disabled={!allValid}
                      >
                        Update Password
                      </button>
                    </form>
                    <h6>Password must contain:</h6>
                    <div className={`${styles["must-container"]}`}>
                      {mustContainData.map((data, index) => (
                        <PasswordRequirement data={data} key={index} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpdatePassword.propTypes = {
  token: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};
