import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert, Spinner, Button } from "reactstrap";
import InvoiceService from "../../services/invoice.service";
import PropTypes from "prop-types";
import DeletableInvoiceListItem from "./deletable-invoice-list-item.component";
import UserService from "../../services/user.service";

/**
 * This component displays a list of invoices in a modal. It allows uploading,
 * viewing, and deleting invoices.
 */
export default class InvoiceListModal extends Component {
  static propTypes = {
    userId: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      name: "",
      error: "",
      warning: "",
      loading: false
    }
    this.fileInput = React.createRef();

    this.getInvoiceList = this.getInvoiceList.bind(this);
    this.uploadInvoice = this.uploadInvoice.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.deleteInvoice = this.deleteInvoice.bind(this);
  }

  componentDidMount() {
    UserService.get(this.props.userId)
      .then((resp) => {
        this.setState({ name: `${resp.data.firstName} ${resp.data.lastName}` });
      });
  }

  getInvoiceList() {
    InvoiceService.list(this.props.userId)
      .then((resp) => this.setState({ files: resp.data }));
  }

  uploadInvoice(e, allowOverwrite) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ loading: true, error: "", warning: "" });
    InvoiceService.upload(this.fileInput.current.files, this.props.userId, allowOverwrite)
      .then(() => {
        this.getInvoiceList();
        this.fileInput.current.value = null;
      })
      .catch((err) => {
        if (err.response.status === 409) {
          this.setState({ warning: `${err.response.data.message} Continue anyway?` });
        } else {
          this.setState({ error: err.response.data.message });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  downloadInvoice(e) {
    e.preventDefault();
    const userId = this.props.userId;
    const filename = e.target.dataset.filename;
    InvoiceService.download(userId, filename)
      .then((resp) => {
        const blob = new Blob([resp.data], { type: resp.headers["content-type"] });
        window.open(window.URL.createObjectURL(blob), "_blank");
      });
  }

  deleteInvoice(filename) {
    InvoiceService.delete(filename)
      .then(() => this.getInvoiceList())
      .then(() => this.setState({ error: "" }));
  }

  render() {

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        onOpened={this.getInvoiceList}
        size="md"
        scrollable>

        <ModalHeader toggle={this.props.toggle}>
          Invoice List ({this.state.name})
        </ModalHeader>

        <ModalBody>
          {/* Error Banner */}
          {this.state.error &&
            <Alert color="danger">
              {this.state.error}
            </Alert>}

          {/* Warning Banner */}
          {this.state.warning &&
            <Alert color="warning" className="d-flex align-items-center">
              {this.state.warning}
              <Button
                color="link"
                size="sm"
                className="text-danger"
                onClick={() => { this.uploadInvoice(null, true) }}>Yes</Button>
              <Button
                color="link"
                size="sm"
                className="text-secondary"
                onClick={() => { this.setState({ warning: "" }) }}>No</Button>
            </Alert>}

          {/* Invoice List */}
          {this.state.files.map((filename) => {
            return (
              <DeletableInvoiceListItem
                key={filename}
                filename={filename}
                userId={this.props.userId}
                deleteInvoice={this.deleteInvoice}
              />);
          })}
          {this.state.files.length === 0 &&
            <p>No invoices found.</p>
          }
        </ModalBody>

        <ModalFooter>
          <form
            onSubmit={this.uploadInvoice}
            className="w-100 d-flex justify-content-between align-items-center">
            <input
              type="file"
              accept="application/pdf"
              ref={this.fileInput}
            />

            <div className="d-flex align-items-center mx-1">
              {this.state.loading &&
                <Spinner color="secondary" size="sm" className="mx-1" />}

              <input
                type="submit"
                className="btn btn-secondary btn-sm"
                value="Upload"
                disabled={this.state.loading}
              />
            </div>
          </form>
        </ModalFooter>

      </Modal>
    );
  }
}
