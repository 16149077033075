import React from "react";
import Accordion from "./accordion/accordion.component";
import Navbar from "../navbar/navbar.component";
import Footer from "../footer/footer.component";

export default function FAQ(props) {
  return (
    <div className="d-flex flex-column">
      <Navbar />
      <div className="container mt-3 my-auto">
        <h3>Frequently Asked Questions</h3>
        <Accordion
          question="How to register an account?"
          answer={"To register a BCT account, please visit " + ['<a href="https://activities-ubf-buffalo.nbsstore.net/behavioral-choice-task">https://activities-ubf-buffalo.nbsstore.net/behavioral-choice-task</a>'] + " to enter the required information and make payment. Once the account is ready, you will receive an email for further instruction. Please note that the process might take 1 - 2 days."}
        />
        <Accordion
          question="How to download the app?"
          answer={"To download the BCT Application, please visit " + ['<a href="https://bct.med.buffalo.edu">https://bct.med.buffalo.edu</a>'] + " to download the installer. There are installers for MacOS and Windows Operating System."}
        />
        <Accordion
          question="How can I purchase BCT?"
          answer={"To purchase BCT, please visit " + ['<a href="https://activities-ubf-buffalo.nbsstore.net/behavioral-choice-task">https://activities-ubf-buffalo.nbsstore.net/behavioral-choice-task</a>'] + "."}
        />
        <Accordion
          question="Who can I call to seek help?"
          answer={"If you need help, you can email us at " + ['<a href="mailto: behavioralchoicetask@buffalo.edu">behavioralchoicetask@buffalo.edu</a>']}
        />
        <Accordion
          question="If I want to reset my password, what steps do I need to follow?"
          answer={"To reset password, you can go to " + ['<span style="color: #0000ee">Settings</span>'] + " in BCT Application, under " + ['<span style="color: #0000ee">Security</span>'] + " Tab, you will see reset password page. Note: In order to reset password, make sure you are connected to the internet."}
        />
      </div>
      <Footer />
    </div>
  );
}
