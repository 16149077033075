import React, { Component } from "react";
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../../services/auth.service';

/**
 * This component will redirect the user to the login page if
 * AuthService.verifyDashboardAccess() returns false. Otherwise, it will show
 * them this.props.children.
 */
export default class ProtectedRoute extends Component {
  static propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    children: PropTypes.node
  }

  constructor(props) {
    super(props);
    this.state = { loading: true, hasPermission: false };
  }

  componentDidMount() {
    AuthService.verifyAdmin()
      .then((hasPermission) => {
        this.setState({ loading: false, hasPermission })
      })
  }

  render() {
    if (this.state.loading) {
      return (<></>);
    } else if (!this.state.hasPermission) {
      return (
        <Route>
          <Redirect to="/login" />
        </Route>);
    }

    return (
      <Route exact={this.props.exact} path={this.props.path}>
        {this.props.children}
      </Route>
    );
  }
}
