import React, { Component } from 'react';
import RegistrationForm from './register.component';

export default class Registration extends Component {
  render() {
    return (
      <div>
        <h2>Add a New User</h2>
        <hr />
        <RegistrationForm />
      </div>
    );
  }
}