import React, { Component } from "react";
import validator from "validator";
import AuthService from "../../services/auth.service";
import PropTypes from "prop-types";
import BootstrapFormField from "../bootstrap/bootstrap-form-field.component";

export default class LoginForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func
  }


  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.login = this.login.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onAuthenticationSuccess = this.onAuthenticationSuccess.bind(this);
    this.onAuthenticationError = this.onAuthenticationError.bind(this);
  }

  defaultState() {
    return {
      email: "",
      password: "",
      emailError: "Please enter a valid email.",
      passwordError: "Please enter your password.",
      authenticationError: "",
      wasValidated: false
    };
  }

  login(e) {
    e.preventDefault();
    this.setState({ wasValidated: true, authenticationError: "" });
    if (this.state.emailError || this.state.passwordError) {
      return;
    }
    const credentials = {
      email: this.state.email,
      password: this.state.password
    };
    // this.setState({authenticating: true});
    AuthService.signin(credentials)
      .then(this.onAuthenticationSuccess)
      .catch(this.onAuthenticationError);
  }

  onAuthenticationSuccess(userInfo) {
    AuthService.verifyAdmin()
      .then((hasPermission) => {
        if (!hasPermission) {
          this.setState({
            ...this.defaultState(),
            email: this.state.email,
            emailError: this.state.emailError,
            authenticationError: "Invalid credentials."
          });
          return;
        }

        if (this.props.onSuccess) {
          this.props.onSuccess(userInfo);
        }
      })
  }

  onAuthenticationError(error) {
    const message = error.response.data.message;
    // Clear password on error and display error message
    this.setState({
      ...this.defaultState(),
      email: this.state.email,
      emailError: this.state.emailError,
      authenticationError: message
    });
  }

  onEmailChange(e) {
    const email = e.target.value;
    if (!validator.isEmail(email) || validator.isEmpty(email)) {
      this.setState({ emailError: "Please enter a valid email." });
    } else {
      this.setState({ emailError: "" });
    }
    this.setState({ email });
  }

  onPasswordChanged(e) {
    const password = e.target.value;
    if (validator.isEmpty(password)) {
      this.setState({ passwordError: "Please enter your password." });
    } else {
      this.setState({ passwordError: "" });
    }
    this.setState({ password });
  }

  render() {
    return (
      <form onSubmit={this.login}>
        {this.state.authenticationError &&
          (<div className="alert alert-danger">
            {this.state.authenticationError}
          </div>)}

        <BootstrapFormField
          className="form-group"
          label="Email"
          type="text"
          id="email"
          value={this.state.email}
          onChange={this.onEmailChange}
          error={this.state.emailError}
          wasValidated={this.state.wasValidated}
        />

        <BootstrapFormField
          className="form-group"
          label="Password"
          type="password"
          id="password"
          value={this.state.password}
          onChange={this.onPasswordChanged}
          error={this.state.passwordError}
          wasValidated={this.state.wasValidated}
        />

        <input
          className="btn btn-primary btn-block"
          type="submit"
          value="Login"
          disabled={this.state.authenticating}
        />
      </form>
    );
  }
}
