import React, { Component } from "react";
import LoginCard from "./login-card.component";
import styles from "./login.module.css"
import AuthService from "../../services/auth.service";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true };

    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    AuthService.verifyAdmin()
      .then((hasPermission) => {
        if (hasPermission) {
          this.onSuccess();
        } else {
          this.setState({ loading: false });
        }
      });
  }

  // Redirect to dashboard on successful login.
  onSuccess() {
    this.props.history.push("/dashboard")
  }

  render() {
    let page = <></>;
    if (!this.state.loading) {
      page = (
        <div className="container-fluid px-0 h-100 bg-ub-blue">
          <div className="row no-gutters h-75 align-items-center">
            <div className={`${styles["login-card-wrapper"]} col-xl-3 mx-auto`}>
              <LoginCard onSuccess={this.onSuccess} />
            </div>
          </div>
        </div>
      );
    }

    return page;
  }
}
