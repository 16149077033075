import React, { Component } from 'react';
import InvoiceListItem from './invoice-list-item.component';
import { Button } from 'reactstrap';
import PropTypes from "prop-types";

/**
 * This component shows a InvoiceListItem with a delete button next to it. Once
 * pressed, it will ask for confirmation. If the user confirms that they want to
 * delete the invoice, it will call this.props.deleteInvoice() with the filename
 * as the argument.
 */
export default class DeletableInvoiceListItem extends Component {

  static propTypes = {
    userId: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = { showConfirmation: false };

    this.showConfirmDelete = this.showConfirmDelete.bind(this);
    this.hideConfirmDelete = this.hideConfirmDelete.bind(this);
  }

  showConfirmDelete(e) {
    e.preventDefault();
    this.setState({ showConfirmation: true });
  }

  hideConfirmDelete(e) {
    e.preventDefault();
    this.setState({ showConfirmation: false });
  }

  render() {
    return (
      <div>
        <div
          className="d-flex justify-content-between align-items-center py-1">
          <InvoiceListItem
            userId={this.props.userId}
            filename={this.props.filename}
            revokeOnUnmount={this.props.revokeOnUnmount} />

          {this.state.showConfirmation
            ? // Ask for confirmation if the user clicked the delete button
            (<div className="d-flex align-items-center">
              <span>Are you sure?</span>

              <Button
                color="link"
                className="text-danger"
                size="sm"
                onClick={() => this.props.deleteInvoice(this.props.filename)}
              >Yes</Button>

              <Button
                color="link"
                className="text-secondary"
                size="sm"
                onClick={this.hideConfirmDelete}>No</Button>
            </div>)

            : // The initial delete button
            (<button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={this.showConfirmDelete}>
              <img
                src="/fontawesome/trash.svg"
                alt="Delete"
                width="16" height="16"
              />
            </button>)}
        </div>

      </div>

    );
  }
}
