import React, { Component } from "react";
import LoginForm from "./login-form.component";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default class LoginCard extends Component {
  static propTypes = {
    onSuccess: PropTypes.func
  }

  render() {
    return (
      <div className={`${this.props.className ? this.props.className : ""} card`}>
        <div className="card-body">
          <Link to="/">
            <img
              className="d-block mx-auto"
              src="/icons/128x128.png"
              width="128"
              height="128"
              alt="BCT"
            />
          </Link>
          <h5 className="card-title text-center">Behavioral Choice Task</h5>
          <LoginForm onSuccess={this.props.onSuccess} />
        </div>
      </div>
    );
  }
}
