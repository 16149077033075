import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PropTypes } from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Homepage from "./components/home/homepage.component";
import Dashboard from "./components/dashboard/dashboard.component";
import Login from "./components/login/login.component";
import FAQ from "./components/faq/faq.component";
import UpdatePassword from "./components/update-password/update-password.component";
import ProtectedRoute from "./components/protected-route/protected-route.component";

const MatchPropTypes = PropTypes.shape({
  params: PropTypes.object
})

const UpdatePasswordForm = ({ match }) => (
  <UpdatePassword userId={match.params.userId} token={match.params.token} />
)

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <ProtectedRoute path="/dashboard"><Dashboard /></ProtectedRoute>
          <Route path="/login" component={Login} />
          <Route path="/faq" component={FAQ} />
          <Route path="/update_password/:userId/:token" component={UpdatePasswordForm} />
        </Switch>
      </Router>
    );
  }
}

export default App;

UpdatePasswordForm.propTypes = {
  match: MatchPropTypes
}