import React, { Component } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import InvoiceService from "../../services/invoice.service";

/**
 * This component downloads and creates a link for the invoice specified by
 * userId and filename. On the first click, it downloads the invoice and creates
 * a Blob. A URL is created from the Blob and a link to it replaces the inital
 * button. The URL gets revoked if revokeOnUnmount is set and the component
 * is unmounted.
 */
export default class InvoiceListItem extends Component {
  static propTypes = {
    filename: PropTypes.string.isRequired,
    revokeOnUnmount: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      blobLink: null
    };

    this.onClick = this.onClick.bind(this);
  }

  componentWillUnmount() {
    if (this.state.blobLink && this.props.revokeOnUnmount) {
      window.URL.revokeObjectURL(this.state.blobLink);
    }
  }

  onClick(e) {
    e.preventDefault();
    const filename = this.props.filename;
    InvoiceService.download(filename)
      .then((resp) => {
        const blob = new Blob([resp.data], { type: resp.headers["content-type"] });
        const blobLink = window.URL.createObjectURL(blob);
        window.open(blobLink, "_blank");
        this.setState({ blobLink });
      });
  }

  render() {
    if (!this.state.blobLink) {
      return (
        <Button color="link" onClick={this.onClick}>
          {this.props.filename}
        </Button>
      );
    }

    return (
      <a
        href={this.state.blobLink}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-link"
      >{this.props.filename}</a>
    );
  }
}
