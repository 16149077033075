import React, { Component } from "react";
import DashboardSidenav from "./dashboard-sidenav.component";
import { Redirect, Switch, withRouter } from "react-router-dom";
import UserAdd from "../registration/registration.component";
import UserList from "../user-list/user-list.component";
import styles from "./dashboard.module.css";
import ProtectedRoute from "../protected-route/protected-route.component";
import AuthService from "../../services/auth.service";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  isAuthenticated() {
    const user = AuthService.getCurrentUser();
    return user && user.roles.includes("ROLE_ADMIN");
  }

  render() {
    return (
      <div className={`${styles.wrapper} d-flex container-fluid px-0`}>
        <DashboardSidenav className={styles.sidenav} baseUrl={this.props.match.url} />
        <div className={styles.content}>
          <div className="d-flex flex-column my-3 mx-5">
            <Switch>
              <Redirect from={`${this.props.match.path}`} to={`${this.props.match.path}/users`} exact />
              <ProtectedRoute
                hasPermission={this.isAuthenticated()}
                path={`${this.props.match.path}/registration`}
              >
                <UserAdd />
              </ProtectedRoute>
              <ProtectedRoute
                hasPermission={this.isAuthenticated()}
                path={`${this.props.match.path}/users`}
              >
                <UserList />
              </ProtectedRoute>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
