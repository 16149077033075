const windowsPlatforms = ['Win32', 'Win64']
const macPlatforms = ['MacIntel']

export function getPlatform() {
  const { platform } = window.navigator;
  if (windowsPlatforms.includes(platform)) {
    return 'Windows';
  }
  if (macPlatforms.includes(platform)) {
    return 'Mac';
  }
  return 'Unknown';
}
