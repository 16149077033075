import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-dark bg-harriman-blue py-0">
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <img className={styles.logo} src="/icons/cropped.png" alt="BCT" />
          <div className="px-1">Behavioral Choice Task</div>
        </Link>
        <ul className="navbar-nav justify-content-end mr-4">
          <li className="nav-item">
            <Link to="/faq" className="nav-link text-white">
              FAQ
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}
